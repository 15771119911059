import React from "react";
import AboutHero from "../images/Heros/About-Hero.jpg";
import { Layout, Seo } from "../components";

export default function AboutPage() {
	const bodyText = [
		<p>
			NiteHawk Paintball started out as a neighborhood paintball party in 2004
			thanks to Dale's neighbor Rod Mann. After that first game, Dale was
			hooked, and he started planning more events.
		</p>,
		<p>
			<strong>
				"Everybody wanted to play at least once a month, so I started to host
				games every month." --Dale Burbridge
			</strong>
		</p>,
		<p>
			Since those early days, Nitehawk has grown to 300+ people attending
			various events!
		</p>,
		<p>
			With monthly special events, there's something available for everyone!
		</p>,
	];

	const pageData = {
		heroImg: AboutHero,
		page: "about",
		headerText: "NiteHawk's Story",
		bodyText,
		btnText: "Book a Game",
		btnRoute: "",
		contents: "",
		defaultSport: "",
	};

	return (
		<>
			<Layout
				heroImg={pageData.heroImg}
				page={pageData.page}
				headerText={pageData.headerText}
				bodyText={pageData.bodyText}
				btnText={pageData.btnText}
				btnRoute={pageData.btnRoute}
				contentsDown={pageData.contents}
				defaultSport={pageData.defaultSport}>
				<Seo title='About Us' description='NiteHawk about us page' />
			</Layout>
		</>
	);
}
